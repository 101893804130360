@font-face {
  font-family: Poppins;
  src: local("Poppins"), url(./fonts/Poppins-Regular.ttf) format("truetype");
}
* {
  margin: 0px;
  padding: 0px;
  font-family: "Poppins";
  /* background: #000 !important;
  color: #0f0 !important;
  outline: solid #f00 1px !important; */
}

.link {
  text-decoration: none;
}

.tastebudsLogo {
  padding-top: 5px;
}
.navText {
  color: black;
  font-family: Poppins, Helvetica, Arial, sans-serif;
  font-size: 16px;
  text-align: center;
  margin-bottom: 20px;
}
.navDownload {
  color: red;
  font-family: Poppins, Helvetica, Arial, sans-serif;
  font-size: 16px;
  text-align: right;

  background: white;
  /* padding-right: 15px;
  margin-left: 280px;
  margin-right: 100px;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 10px; */
}
